
import { computed, defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";
import { BatchSituation } from "@/store/batch/types";

const VBatchUpdateStatus = defineComponent({
  name: "VBatchUpdateStatus",
  components: { CModal },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const statusOptions: { label: string; value: BatchSituation; routeName: string }[] = [
      { label: "Pagar", value: "PG", routeName: "batch-pay" },
      { label: "Cancelar", value: "CA", routeName: "batch-cancel" },
    ];
    const currentStatusOption = statusOptions.find((status) => status.routeName === route.name?.toString()) || null;
    useHead({ title: `${currentStatusOption?.label} | obmed` });

    const routeId = Number(route.params._id);
    const loading = reactive({ confirm: false, batch: true });
    const batch = computed(() => store.state.batch.current);

    async function getBatch() {
      loading.batch = true;
      await store.dispatch("getBatch", { _id: route.params._id.toString() });
      loading.batch = false;
    }

    function handleClose() {
      router.back();
    }

    async function handleConfirme() {
      loading.confirm = true;
      const response = await store.dispatch(currentStatusOption?.value === "PG" ? "payBatch" : "cancelBatch", {
        _id: routeId,
        form: { ie_situacao: currentStatusOption?.value },
      });
      loading.confirm = false;

      if ((response?.status || 500) < 300) {
        store.dispatch("getBatches");
        handleClose();
      }
    }

    if (!batch.value) getBatch();

    return { loading, currentStatusOption, routeId, batch, handleConfirme, handleClose };
  },
});

export default VBatchUpdateStatus;
